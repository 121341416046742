
import MediaCreationDialog from "@/components/mediaHub/MediaCreationDialog"
import { AnalyticsEvent } from "@evercam/shared/types"
import { debounce } from "@evercam/shared/utils"

export default {
  name: "MediaHubHeaderActions",
  components: {
    MediaCreationDialog,
  },
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localFilters: {},
      sortParameters: [
        {
          label: this.$t("content.media_hub.sort_by.first_created"),
          value: "created_at|desc",
        },
        {
          label: this.$t("content.media_hub.sort_by.last_created"),
          value: "created_at|asc",
        },
        {
          label: this.$t("content.media_hub.sort_by.title_asc"),
          value: "title|asc",
        },
        {
          label: this.$t("content.media_hub.sort_by.title_desc"),
          value: "title|desc",
        },
        {
          label: this.$t("content.media_hub.sort_by.author_asc"),
          value: "requester_name|asc",
        },
        {
          label: this.$t("content.media_hub.sort_by.author_desc"),
          value: "requester_name|desc",
        },
        {
          label: this.$t("content.media_hub.sort_by.type"),
          value: "type",
        },
      ],
      searchQuery: "",
    }
  },
  computed: {
    sortName() {
      return (
        this.sortParameters.find(
          (param) => param.value === this.localFilters.sortBy
        )?.label || ""
      )
    },
    inputSearchClasses() {
      return {
        "search__field e-ring-1 e-ring-inset e-relative e-block e-pr-6 e-flex-1 e-w-full e-h-full disabled:e-cursor-not-allowed disabled:e-opacity-75 focus:e-outline-none e-border-0 e-text-sm e-placeholder-gray-400 e-px-2.5 e-pr-8 e-py-2 e-gap-x-1.5 e-rounded hover:e-ring-gray-400": true,
        "e-bg-gray-900 e-text-white e-ring-gray-700 focus:e-ring-2 focus:e-ring-blue-500 e-placeholder-gray-500":
          this.$vuetify.theme.dark,
        "e-bg-white e-text-[#000057] e-ring-gray-300 focus:e-ring-2 focus:e-ring-blue-500 e-placeholder-gray-400":
          !this.$vuetify.theme.dark,
      }
    },
  },
  watch: {
    filters: {
      handler(filters) {
        this.localFilters = { ...filters }
        this.searchQuery = filters?.title
      },
      deep: true,
      immediate: true,
    },
    localFilters: {
      handler(newFilters) {
        if (JSON.stringify(newFilters) !== JSON.stringify(this.filters)) {
          this.updateFilters()
        }
      },
      deep: true,
    },
    searchQuery(value) {
      this.updateFiltersDebounced(value)
    },
  },
  methods: {
    onChangeSelectOption(value, key) {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubSelectFilter, {
        type: key,
        value,
      })
    },
    updateFilters() {
      this.$emit("update-filters", this.localFilters)
    },
    updateFiltersDebounced: debounce(function (value) {
      this.localFilters.title = value
    }, 500),
    clearSearch() {
      this.searchQuery = ""
    },
  },
}
