
import type { Media } from "@evercam/shared/types/media"
import Vue, { PropType } from "vue"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import MediaClip from "@evercam/shared/components/medias/MediaClip"

export default Vue.extend({
  name: "MediaHubGallery",
  components: {
    MediaClip,
  },
  props: {
    mediaItems: {
      type: Array as PropType<Array<Media>>,
      default: () => [],
    },
  },
  computed: {
    ...mapStores(useCameraStore),
    computedMediaItems(): Array<Media> {
      return this.mediaItems.map((media: Media) => {
        return {
          ...media,
          thumbnailUrl: media.url,
        }
      })
    },
  },
})
