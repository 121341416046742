
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import { useProjectStore } from "@/stores/project"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"
import { navigateTo } from "#app"
import MediaHubContainer from "@/components/mediaHub/MediaHubContainer"
import { useMediaHubStore } from "@/stores/mediaHub"

export default {
  meta: {
    pageId: AnalyticsEventPageId.MediaHub,
    publiclyAccessible: true,
  },
  components: { MediaHubContainer },
  beforeRouteLeave(to, _, next) {
    if (!to?.path?.includes("/media-hub")) {
      useMediaHubStore().$reset()
    }
    next()
  },
  middleware() {
    const projectStore = useProjectStore()
    if (!projectStore.selectedProjectExid) {
      return navigateTo("/projects/404")
    }
  },
  head() {
    return {
      title: `${this.cameraStore.selectedCamera?.name || "Evercam"} | ${this.$t(
        "pages.media_hub"
      )}`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraStore),
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
}
