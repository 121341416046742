
export default {
  name: "MediaHubGalleryPlaceholder",
  data() {
    return {
      imageHeight: "220px",
    }
  },
  methods: {
    onResize(entries) {
      this.imageHeight = entries.contentRect.width / 2.46 + "px"
    },
  },
}
