
import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  name: "MediaHubPagination",
  props: {
    totalPages: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    goToNextPage() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubGoToNextPage)
    },
    goToPreviousPage() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubGoToPreviousPage)
    },
  },
}
